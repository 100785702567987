import { Injectable, OnDestroy } from '@angular/core'
import { v4 as uuidv4 } from 'uuid'
import { catchError, of, Subject } from 'rxjs'
import {
  BannerListPayload,
  CartInfo,
  ClickChangeItemQuantityInCartPayload,
  FetchNextProductPagePayload,
  ProductFilterByNamePayload,
  ProductFilterByPrimaryCategoryPayload,
  ProductFilterPayload,
  RecordAnalyticsDataBody,
  TargetBannerPayload,
  TargetCouponCard,
  TargetProductPositionPayload,
  UserAnalyticsActionEnum,
  UserAnalyticsPageEnum,
  UserAnalyticsPositionEnum,
} from '@core/interfaces/user-analytics.interface'
import { HttpClient } from '@angular/common/http'
import { EnvConfigurationService } from '@service/auth/env-config.service'
import { ToastService } from '@core/services/toast/toast.service'

@Injectable({
  providedIn: 'root',
})
export class UserAnalyticsService implements OnDestroy {
  private sessionToken: string = ''
  private analyticsSessionTokenKey = 'analyticsSessionToken'
  private destroy$ = new Subject<void>()
  private analyticsUrl = '/activity-tracker/log'
  private analyticsBaseUrl = ''

  constructor(
    private httpClient: HttpClient,
    private envConfigurationService: EnvConfigurationService,
    private toastService: ToastService,
  ) {
    this.getNewSessionToken()
    // Use analytics base url, if not provided, use the same url as backend.
    this.analyticsBaseUrl =
      this.envConfigurationService.getConfig().ANALYTICS_URL ?? this.envConfigurationService.getConfig().BACKEND_URL
    // Exempt analytics api from toast notification.
    this.toastService.addPathToSupressErrorResponse(this.analyticsUrl)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private getNewSessionToken() {
    // Create a new token and replace the old one if existed.
    const newSessionToken = uuidv4()
    sessionStorage.setItem(this.analyticsSessionTokenKey, newSessionToken)
    this.sessionToken = newSessionToken
  }

  public recordAnalyticsData<T = null>(
    action: UserAnalyticsActionEnum,
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: T,
  ) {
    const body: RecordAnalyticsDataBody<T> = {
      action,
      position,
      page,
      sessionId: this.sessionToken,
      payload,
    }
    // Fired the API and forget.
    this.httpClient
      .request<T>('post', `${this.analyticsBaseUrl}${this.analyticsUrl}`, {
        body,
      })
      .pipe(catchError(() => of(null)))
      .subscribe()
  }

  public clickGoToCartIconButton(page: UserAnalyticsPageEnum) {
    this.recordAnalyticsData(
      UserAnalyticsActionEnum.ClickGoToCartIconButton,
      UserAnalyticsPositionEnum.Header,
      page,
      null,
    )
  }

  public clickGoToCartFooterButton(page: UserAnalyticsPageEnum) {
    this.recordAnalyticsData(
      UserAnalyticsActionEnum.ClickGoToCartFooterButton,
      UserAnalyticsPositionEnum.Footer,
      page,
      null,
    )
  }

  public clickChangeItemQuantity(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ClickChangeItemQuantityInCartPayload,
  ) {
    this.recordAnalyticsData<ClickChangeItemQuantityInCartPayload>(
      UserAnalyticsActionEnum.ChangeItemQuantityInCart,
      position,
      page,
      payload,
    )
  }

  public clickIncreaseItemQuantity(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ClickChangeItemQuantityInCartPayload,
  ) {
    this.recordAnalyticsData<ClickChangeItemQuantityInCartPayload>(
      UserAnalyticsActionEnum.ClickIncreaseItemToCart,
      position,
      page,
      payload,
    )
  }

  public clickDecreaseItemQuantity(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ClickChangeItemQuantityInCartPayload,
  ) {
    this.recordAnalyticsData<ClickChangeItemQuantityInCartPayload>(
      UserAnalyticsActionEnum.ClickDecreaseItemFromCart,
      position,
      page,
      payload,
    )
  }

  public clickRemoveItemFromCart(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ClickChangeItemQuantityInCartPayload,
  ) {
    this.recordAnalyticsData<ClickChangeItemQuantityInCartPayload>(
      UserAnalyticsActionEnum.RemoveItemFromCart,
      position,
      page,
      payload,
    )
  }

  public clickSubmitCart(payload: CartInfo) {
    this.recordAnalyticsData<CartInfo>(
      UserAnalyticsActionEnum.SubmitCart,
      UserAnalyticsPositionEnum.Footer,
      UserAnalyticsPageEnum.GtCartPage,
      payload,
    )
  }

  public clickAddWishlistItem(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: TargetProductPositionPayload,
  ) {
    this.recordAnalyticsData<TargetProductPositionPayload>(
      UserAnalyticsActionEnum.AddWishlistItem,
      position,
      page,
      payload,
    )
  }

  public clickRemoveWishlistItem(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: TargetProductPositionPayload,
  ) {
    this.recordAnalyticsData<TargetProductPositionPayload>(
      UserAnalyticsActionEnum.RemoveWishlistItem,
      position,
      page,
      payload,
    )
  }

  public valueChangeOnFilterProductByNameInput(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ProductFilterByNamePayload,
  ) {
    this.recordAnalyticsData<ProductFilterByNamePayload>(
      UserAnalyticsActionEnum.ValueChangeOnFilterProductByName,
      position,
      page,
      payload,
    )
  }

  public valueChangeOnFilterProductByPrimaryCategoryDropdown(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ProductFilterByPrimaryCategoryPayload,
  ) {
    this.recordAnalyticsData<ProductFilterByPrimaryCategoryPayload>(
      UserAnalyticsActionEnum.ValueChangeOnFilterPrimaryCategory,
      position,
      page,
      payload,
    )
  }

  public confirmFilterProduct(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: ProductFilterPayload,
  ) {
    this.recordAnalyticsData<ProductFilterPayload>(
      UserAnalyticsActionEnum.ConfirmFilterProducts,
      position,
      page,
      payload,
    )
  }

  public scrollToNextProductPage(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: FetchNextProductPagePayload,
  ) {
    this.recordAnalyticsData<FetchNextProductPagePayload>(
      UserAnalyticsActionEnum.DisplayProductList,
      position,
      page,
      payload,
    )
  }

  public scrollToNextWishlistPage(
    position: UserAnalyticsPositionEnum,
    page: UserAnalyticsPageEnum,
    payload: FetchNextProductPagePayload,
  ) {
    this.recordAnalyticsData<FetchNextProductPagePayload>(
      UserAnalyticsActionEnum.DisplayWishlistList,
      position,
      page,
      payload,
    )
  }

  public userSeeBanner(page: UserAnalyticsPageEnum, payload: TargetBannerPayload) {
    this.recordAnalyticsData<TargetBannerPayload>(
      UserAnalyticsActionEnum.DisplayBanner,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public systemLoadBanner(page: UserAnalyticsPageEnum, payload: BannerListPayload) {
    this.recordAnalyticsData<BannerListPayload>(
      UserAnalyticsActionEnum.LoadBanner,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public userNavigateNextBanner(page: UserAnalyticsPageEnum, payload: TargetBannerPayload) {
    this.recordAnalyticsData<TargetBannerPayload>(
      UserAnalyticsActionEnum.NavigateBannerNext,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public userNavigatePrevBanner(page: UserAnalyticsPageEnum, payload: TargetBannerPayload) {
    this.recordAnalyticsData<TargetBannerPayload>(
      UserAnalyticsActionEnum.NavigateBannerPrev,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public userNavigateJumpBanner(page: UserAnalyticsPageEnum, payload: TargetBannerPayload) {
    this.recordAnalyticsData<TargetBannerPayload>(
      UserAnalyticsActionEnum.NavigateBannerJump,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public clickBanner(page: UserAnalyticsPageEnum, payload: TargetBannerPayload) {
    this.recordAnalyticsData<TargetBannerPayload>(
      UserAnalyticsActionEnum.ClickSeeDetail,
      UserAnalyticsPositionEnum.BannerCarousel,
      page,
      payload,
    )
  }

  public seeCouponDetail(position: UserAnalyticsPositionEnum, page: UserAnalyticsPageEnum, payload: TargetCouponCard) {
    this.recordAnalyticsData<TargetCouponCard>(UserAnalyticsActionEnum.ClickSeeDetail, position, page, payload)
  }
}
