export enum UserAnalyticsActionEnum {
  // General Action Enum
  ClickSeeDetail = 'CLICK_SEE_DETAIL',
  // GT Product Action Enum
  ClickGoToCartIconButton = 'CLICK_GO_TO_CART_ICON_BUTTON',
  ClickGoToCartFooterButton = 'CLICK_GO_TO_CART_FOOTER_BUTTON',
  ClickDecreaseItemFromCart = 'DECREASE_ITEM_FROM_CART',
  ClickIncreaseItemToCart = 'INCREASE_ITEM_TO_CART',
  ChangeItemQuantityInCart = 'CHANGE_ITEM_QUANTITY_IN_CART',
  RemoveItemFromCart = 'REMOVE_ITEM_FROM_CART',
  SubmitCart = 'SUBMIT_CART',
  AddWishlistItem = 'ADD_WISHLIST_ITEM',
  RemoveWishlistItem = 'REMOVE_WISHLIST_ITEM',
  ValueChangeOnFilterPrimaryCategory = 'VALUE_CHANGE_ON_FILTER_PRODUCTS_BY_PRIMARY_CATEGORY',
  ValueChangeOnFilterProductByName = 'VALUE_CHANGE_ON_FILTER_PRODUCTS_BY_NAME',
  ConfirmFilterProducts = 'CONFIRM_FILTER_PRODUCTS',
  DisplayProductList = 'DISPLAY_PRODUCT_LIST',
  DisplayWishlistList = 'DISPLAY_WISHLIST_LIST',
  // GT Banner Action Enum
  DisplayBanner = 'DISPLAY_BANNER',
  NavigateBanner = 'NAVIGATE_BANNER',
  NavigateBannerNext = 'NAVIGATE_BANNER_NEXT',
  NavigateBannerPrev = 'NAVIGATE_BANNER_PREV',
  NavigateBannerJump = 'NAVIGATE_BANNER_JUMP',
  LoadBanner = 'LOAD_BANNER',
}

export enum UserAnalyticsPageEnum {
  GtHomepage = 'GT_HOMEPAGE',
  GtBannerProduct = 'GT_BANNER_PRODUCT',
  GtCartPage = 'GT_CART_PAGE',
}

export enum UserAnalyticsPositionEnum {
  Empty = '',
  WishlistList = 'WISHLIST_LIST',
  ProductList = 'PRODUCT_LIST',
  CouponCard = 'COUPON_CARD',
  CouponCardDetail = 'COUPON_CARD_DETAIL',
  BannerCarousel = 'BANNER_CAROUSEL',
  SearchAndFilter = 'SEARCH_AND_FILTER',
  SearchResult = 'SEARCH_RESULT',
  Header = 'HEADER',
  Footer = 'FOOTER',
}

export interface RecordAnalyticsDataBody<T> {
  action: UserAnalyticsActionEnum
  position: UserAnalyticsPositionEnum
  page: UserAnalyticsPageEnum
  sessionId: string
  payload: T
}

export interface ClickChangeItemQuantityInCartPayload {
  packageId: number
  index: number
  previousQuantity: number
  newQuantity: number
}

export interface TargetProductPositionPayload {
  index: number
  packageId: number
}

export interface FetchNextProductPagePayload {
  packageIdList: number[]
}

export interface ProductFilterByNamePayload {
  search: string
}

export interface ProductFilterByPrimaryCategoryPayload {
  primaryCategoryName: string | number | null
}

export interface ProductFilterPayload extends ProductFilterByNamePayload, ProductFilterByPrimaryCategoryPayload {}

export interface TargetBannerPayload {
  bannerId: number
  index: number
}

export interface BannerListPayload {
  bannerIdList: number[]
}

export interface TargetCouponCard {
  couponId: number
  index: number
}

export interface CartInfo {
  packageIdList: number[]
  orderId: number
}
